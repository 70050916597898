import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/ZE1mJ0ggpag">
    <SEO title="Personal Stewardship - Marriage, Money, and Minions" />
  </Layout>
)

export default SermonPost
